var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticStyle:{"margin-top":"7px"},attrs:{"label":_vm.tittle}},[_c('date-range-picker',{ref:"picker",attrs:{"opens":'left',"locale-data":{
      direction: 'ltr',
      format: 'mm-dd-yyyy',
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 0,
    },"timePicker":false,"timePicker24Hour":true,"showWeekNumbers":false,"showDropdowns":true,"autoApply":true,"linkedCalendars":true},on:{"update":_vm.handleDateRangeUpdate},scopedSlots:_vm._u([{key:"ranges",fn:function(ranges){return [_c('div',{staticClass:"ranges"},[_c('ul',_vm._l((ranges.ranges),function(range,name){return _c('li',{key:name,on:{"click":function($event){return ranges.clickRange(range)}}},[_c('b',[_vm._v(_vm._s(name))])])}),0)])]}}]),model:{value:(_vm.localDateRange),callback:function ($$v) {_vm.localDateRange=$$v},expression:"localDateRange"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }